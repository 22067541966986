/*-------------------------
	Task Subsystem Customisations
--------------------------*/
html {
  overflow-y: scroll;
}
html,
body {
  height: 100%;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background-color: #f5f6f9;
}
html,
body,
ul,
ol,
li,
a,
p,
div,
span,
header,
section,
main,
nav,
form,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
  border: none;
  margin: 0;
  padding: 0;
}
loading-pane > .loading-pane.loading::after {
  width: 1.5rem;
  height: 1.5rem;
  border-color: black;
  border-width: 6px;
  border-radius: 50%;
  z-index: 999;
  margin-left: -0.75rem;
  margin-top: -0.75rem;
  opacity: 0.5;
}
.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
app-root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
body {
  display: flex;
  flex-direction: column;
}
.text-danger {
  color: firebrick;
}
@media screen and (max-width: 900px) {
  html,
body {
    font-size: 12px;
  }
}
@page {
  size: A4;
  margin: 10mm 10mm 10mm 10mm;
}
@media print {
  app-root,
body {
    display: block;
  }
}
/*-------------------------
	Task Subsystem Customisations
--------------------------*/
/*-------------------------
	The content section
--------------------------*/
#content {
  background-color: #FFF;
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  margin: 0 auto 5px;
}
/*-------------------------
    Inline help tip
    Courtesy of: https://tutorialzine.com/2014/07/css-inline-help-tips
--------------------------*/
.help-tip {
  position: absolute;
  top: -3px;
  right: 18px;
  text-align: center;
  background-color: #9dcde6;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  cursor: default;
}
.help-tip:before {
  content: "?";
  font-weight: bold;
  color: #fff;
}
.help-tip:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}
.help-tip p {
  z-index: 100;
  display: none;
  text-align: left;
  background-color: #1E2021;
  padding: 20px !important;
  width: 300px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  right: -300px;
  color: #FFF;
  font-size: 13px;
  line-height: 1.4;
  opacity: 100;
}
.help-tip :past:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #1E2021;
  right: 10px;
  top: -12px;
}
.help-tip p:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -300px;
  left: 0;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
/*-------------------------
	To 'Top' of page button
--------------------------*/
#toTopBtn {
  position: fixed;
  /* Fixed/sticky position */
  bottom: 20px;
  /* Place the button at the bottom of the page */
  right: 30px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  background-color: #999999;
  /* Set a background color */
  color: black;
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 15px;
  /* Some padding */
  border-radius: 10px;
  /* Rounded corners */
  font-size: 18px;
  /* Increase font size */
}
#toTopBtn:hover {
  background-color: #555;
  /* Add a dark-grey background on hover */
}
/*-------------------------
	Adjustments to Bulma styles
--------------------------*/
.tile {
  padding: 0.5rem !important;
}
.break-word {
  word-break: break-word;
  word-wrap: break-word;
}
.column.is-one-third {
  padding: 0.25rem !important;
}
.body .sidebar {
  background-color: #dddddd;
}
.body .main {
  background-color: #eeeeee;
}
.tabs:not(:last-child) {
  margin-bottom: 0.75rem !important;
}
.content ul {
  margin-top: 0.25em !important;
}
.notification {
  padding: 0 0 !important;
}
@media all {
  .tile {
    flex-basis: auto !important;
    width: 100%;
  }
}
.pr-1 {
  padding-right: 1px;
}
.pl-1 {
  padding-left: 1px;
}
.pr-2 {
  padding-right: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-3 {
  padding-right: 3px;
}
.pl-3 {
  padding-left: 3px;
}
.pr-4 {
  padding-right: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-10 {
  padding-top: 10px;
}
.mr-1 {
  margin-right: 1px;
}
.ml-1 {
  margin-left: 1px;
}
.mr-2 {
  margin-right: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.ml-3 {
  margin-left: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-6 {
  margin-top: 3rem;
}
.mr5 {
  margin-right: 5px;
}
.mt5 {
  margin-top: 5px;
}
.mt20 {
  margin-top: 20px;
}
select {
  padding: 7px;
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: #000000;
  font-size: 13px;
  line-height: 1.4;
  min-width: 230px;
}
.wide-button {
  min-width: 150px;
}
.button {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2) !important;
  padding: 7px;
  background-color: #f6f7f9;
}
/*-------------------------
	Traffic Light Styles
--------------------------*/
.circle {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  align-items: center;
}
.row.circle {
  align-items: center !important;
}
.circle.red {
  background-color: #c0392b;
  box-shadow: 0 0 0 0 #c0392b;
}
.circle.yellow {
  background-color: #f1c40f;
  box-shadow: 0 0 0 0 #f1c40f;
}
.circle.green {
  background-color: #2ecc71;
  box-shadow: 0 0 0 0 #2ecc71;
}
.circle.rules {
  background-color: #f98d2b;
  box-shadow: 0 0 0 0 #f98d2b;
}
/*-------------------------
  Rules Table Layout Style
--------------------------*/
.rules-section {
  margin-top: 20px !important;
}
.spaced {
  padding: 5px 0px 5px 0px;
}
.rule-column {
  padding: 1.25rem 0 1.25rem 0;
}
.rule-column:nth-child(1) {
  flex: 2 0 !important;
}
.rule-column:nth-child(2) {
  flex: 1 0 !important;
}
.rule-column:nth-child(3) {
  flex: 10 0 !important;
}
.content-shaded {
  background-color: #eaecf0;
}
.content-site {
  background-color: white;
}
section-title {
  font-weight: 700;
}
.emphasis {
  background-color: #eaecf0;
  font-size: 0.9em;
  font-weight: bolder;
  font-style: oblique;
}
.highlight {
  background-color: yellow;
}